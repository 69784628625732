<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)"
  (keydown.enter)="onControlEnter($event)" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Sales.MasterPriceTable.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Title'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.title' | translate | headtitlecase}} (*)</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.title')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Title"
                    placeholder="{{'Common.title' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Type'), 'warning')">
                  <label class="label"><span class="valid">{{'Sales.MasterPriceTable.type' | translate | headtitlecase}} (*)</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Sales.MasterPriceTable.type')} | headtitlecase }}
                      (*)</span></label>
                  <nb-select fullWidth formControlName="Type"
                    placeholder="{{'Sales.chooseMasterPriceTableType' | translate | headtitlecase}}">
                    <nb-option *ngFor="let type of typeList" [value]="type.id">
                      {{type.text}}</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('PrintTemplate'), 'warning')">
                  <label class="label"><span class="valid">{{'Print.template' | translate | headtitlecase}} (*)</span><span
                      class="invalid">{{ 'Form.printTemplate' | translate:{field: commonService.translate.instant('Common.title')} | headtitlecase }}
                      (*)</span></label>
                  <nb-select fullWidth formControlName="PrintTemplate"
                    placeholder="{{'Print.chooseTemplate' | translate | headtitlecase}}">
                    <nb-option *ngFor="let printTemplate of printTemplateList" [value]="printTemplate.id">
                      {{printTemplate.text}}</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Common.description' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.description')} | headtitlecase }}
                      (*)</span></label>
                  <textarea nbInput fullWidth formControlName="Description"
                    placeholder="{{'Common.description' | translate | headtitlecase}}" rows="4"></textarea>
                </div>
              </div>
            </div>

            <!-- Detail form -->
            <!-- <hr> -->
            <!-- <label class="label">{{'Voucher.productOrservice' | translate | headtitlecase}}</label> -->

            <div class="row title">
              <div class="col-md-12">
                <label class="label"><span class="valid">Chi tiết bảng giá bán ra</span></label>
              </div>
            </div>
            <!-- <div class="buttons-row">
              <button nbButton hero status="primary" (click)="printQrcode(formItem.value)" [disabled]="!form.valid || isProcessing">
                <nb-icon pack="eva" icon="printer"></nb-icon>{{'In thẻ giá khu trưng bày (demo)' | translate | headtitlecase}}
              </button>
            </div> -->
            <div class="ng2-smart-table-wrap">
              <div class="ng2-smart-table-wrap">
                <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="source"
                  (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)"
                  (edit)="onEditAction($event)" (create)="onSerialAction($event)">
                </ng2-smart-table>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12" style="text-align: right;">

              </div>
            </div>
            <!-- End Detail form -->


          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <!-- <button nbButton hero status="primary" (click)="printQrcode(formItem.value)" [disabled]="!form.valid || isProcessing">
              <nb-icon pack="eva" icon="printer"></nb-icon>{{'In thẻ giá khu trưng bày (demo)' | translate | headtitlecase}}
            </button> -->
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero
              (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;"
              hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
