<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('AdminProduct.Category.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật danh mục sản phẩm
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code_old">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Code'), 'warning')">
                  <label class="label"><span class="valid">Mã</span><span class="invalid">Mã là bắt buộc
                      (*)</span>
                      <nb-icon class="parent-copy-btn" (click)="copyFormControlValueToOthers(array, i, 'Code')"
                        pack="eva" icon="copy-outline">
                      </nb-icon></label>
                      <input type="text" nbInput fullWidth formControlName="Code" maxlength="15" placeholder="Tự động tạo">
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Parent'), 'warning')">
                  <label class="label"><span class="valid">Danh mục cha</span><span class="invalid">Danh mục cha là bắt buộc
                      (*)</span>
                      <nb-icon class="parent-copy-btn" (click)="copyFormControlValueToOthers(array, i, 'Parent')"
                        pack="eva" icon="copy-outline">
                      </nb-icon></label>
                      <ngx-select2 formControlName="Parent"
                            [select2Option]="select2OptionForParent">
                          </ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('FindOrder'), 'warning')">
                  <label class="label"><span class="valid">Thứ tự tìm kiếm</span><span class="invalid">Thứ tự tìm kiếm là bắt buộc
                      (*)</span>
                      <nb-icon class="parent-copy-btn" (click)="copyFormControlValueToOthers(array, i, 'FindOrder')"
                        pack="eva" icon="copy-outline">
                      </nb-icon></label>
                      <input type="text" nbInput fullWidth formControlName="FindOrder" placeholder="Thứ tự tìm kiếm">
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên danh mục</span><span class="invalid">Tên danh mục là bắt buộc
                      (*)</span>
                      <nb-icon class="parent-copy-btn" (click)="copyFormControlValueToOthers(array, i, 'Name')"
                        pack="eva" icon="copy-outline">
                      </nb-icon></label>
                      <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên danh mục">
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero style="float: left;">Thêm cái nữa</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
