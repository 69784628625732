<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)"
  (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Purchase.PriceTable.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            {{ 'Sales.PriceTable.title' | translate:{definition: '', action: commonService.translate.instant('Common.' + (this.id && this.id[0] ? 'update' : 'create'))} | headtitlecase }}
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;"
              title="{{'Common.close' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="close"></nb-icon>{{'Common.close' | translate | headtitlecase}}
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              [disabled]="isProcessing" title="{{'Common.reload' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="refresh"></nb-icon>{{'Common.reload' | translate | headtitlecase}}
            </button>
            <button nbButton status="primary" hero size="tiny" (click)="preview(formItem)" style="float: right;"
              [disabled]="isProcessing" title="{{'Common.print' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()"
              [disabled]="!canUndo || isProcessing" style="float: right;"
              title="{{'Common.undo' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>{{'Common.undo' | translate | headtitlecase}}
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Supplier'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Voucher.organizationOrPersornal' | translate | headtitlecase}}</span><span
                      class="invalid">
                      {{'Voucher.organizationOrPersornal' | translate | headtitlecase}} (*)</span></label>
                  <ngx-select2 formControlName="Supplier" [select2Option]="select2ContactOption"
                    (selectChange)="onSupplierChange(formItem, $event, i)"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierName'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.name' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.name')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierName"
                    placeholder="{{'Common.name' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Recipient'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Common.recipient' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.recipient')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Recipient"
                    placeholder="{{'Common.recipient' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierAddress'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.address' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.address')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierAddress"
                    placeholder="{{'Common.address' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierPhone'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.phone' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.phone')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierPhone"
                    placeholder="{{'Common.phone' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierEmail'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.email' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.email')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierEmail"
                    placeholder="{{'Common.email' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierTaxCode'), 'warning')">
                  <label class="label"><span class="valid">{{'Contact.taxCode' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Contact.taxCode')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierTaxCode"
                    placeholder="{{'Contact.taxCode' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierBankName'), 'warning')">
                  <label class="label"><span class="valid">{{'Bank.name' | translate | headtitlecase}}</span><span
                      class="invalid">Tên ngân hàng
                      là{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Bank.name')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierBankName"
                    placeholder="{{'Bank.name' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierBankCode'), 'warning')">
                  <label class="label"><span class="valid">{{'Bank.code' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Bank.code')} | headtitlecase }}
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierBankCode"
                    placeholder="{{'Bank.code' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('DirectReceiverName'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Sales.directReceiver' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Sales.directReceiver')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="DirectReceiverName"
                    placeholder="{{'Sales.directReceiver' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DeliveryAddress'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Sales.deliveryAddress' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Sales.deliveryAddress')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="DeliveryAddress"
                    placeholder="{{'Sales.deliveryAddress' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('PaymentStep'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Sales.paymentStep' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Sales.paymentStep')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="PaymentStep"
                    placeholder="{{'Sales.paymentStep' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Title'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.title' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.title')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Title"
                    placeholder="{{'Common.title' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DateOfApprove'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Sales.dateOfApprove' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Sales.dateOfApprove')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" [owlDateTime]="DateOfApprove" [owlDateTimeTrigger]="DateOfApprove" nbInput fullWidth
                    formControlName="DateOfApprove" placeholder="{{'Common.dateTime' | translate | headtitlecase}}">
                  <owl-date-time #DateOfApprove></owl-date-time>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.description' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Common.description')} | headtitlecase }}
                      (*)</span></label>
                  <textarea nbInput fullWidth formControlName="Description"
                    placeholder="{{'Common.description' | translate | headtitlecase}}" rows="4"></textarea>
                </div>
              </div>
            </div>

            <!-- Detail form -->
            <!-- <hr> -->
            <label class="label">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
            <div class="row">
              <div class="col-sm-2 label">
                {{'Common.noNumber' | translate | headtitlecase}}/{{'Common.photo' | translate | headtitlecase}}</div>
              <div class="col-sm-3 label">{{'Voucher.productOrService' | translate | headtitlecase}}</div>
              <div class="col-sm-7 label">
                <div class="row">
                  <div class="col-sm-2 label align-right">{{'Voucher.quantity' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label">{{'Voucher.unit' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label align-right">{{'Voucher.price' | translate | headtitlecase}}</div>
                  <div class="col-sm-3 label">{{'Voucher.tax' | translate | headtitlecase}}</div>
                  <div class="col-sm-3 label align-right">{{'Voucher.toMoney' | translate | headtitlecase}}</div>
                </div>
              </div>
            </div>

            <div formArrayName="Details" class="form-details" [sortablejs]="getDetails(formItem)"
              [sortablejsOptions]="{ handle: '.sorting-handle' }">
              <!-- <div formArrayName="Details" class="form-details"> -->
              <div class="form-detail-item" *ngFor="let detail of getDetails(formItem).controls; let ic=index"
                [formGroup]="detail">
                <hr>
                <div class="row">
                  <div class="col-lg-2 col-md-4 col-sm-12 col-is-12">
                    <div class="row">
                      <div class="col-md-3 col-sm-3 col-is-3">
                        <div class="form-group">
                          <label class="label">{{'Common.noNumber' | translate | headtitlecase}}</label>
                          <input nbInput fullWidth disabled="true" value="{{ ic + 1 }}"
                            style="text-align: center; cursor: grab; width: 100%; height: 2.5rem; padding: 0;">
                          <input type="hidden" formControlName="Id">
                        </div>
                        <div class="form-group">
                          <label class="label">{{'Form.cm' | translate | headtitlecase}}</label>
                          <button nbButton status="danger" hero size="medium"
                            (click)="removeDetailGroup(formItem, detail, ic)"
                            style="width: 100%; margin-bottom: 3px; padding: 0">
                            <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-9 col-sm-9 col-is-9">
                        <div class="form-group">
                          <label class="label">{{'Common.image' | translate | headtitlecase}}</label>
                          <input type="button" nbInput fullWidth formControlName="Image"
                            placeholder="{{'Common.image' | translate | headtitlecase}}" style="
                          height: 6rem;
                          background-repeat: no-repeat;
                          background-size: contain;
                          background-position: center;
                          background-image: url(assets/icon/eva/image-outline.svg);" class="sorting-handle">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-8 col-sm-12 col-is-12">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-is-12">
                        <div class="form-group" [attr.state]="formControlValidate(detail.get('Product'), 'warning')">
                          <label class="label"><span
                              class="valid">{{'Voucher.productOrService' | translate | headtitlecase}}</span><span
                              class="invalid">{{ 'Form.requireLabel' | translate:{field: commonService.translate.instant('Vouhcer.productOrService')} | headtitlecase }}
                              (*)</span></label>
                          <ngx-select2 formControlName="Product" [select2Option]="select2OptionForProduct"
                            (selectChange)="onSelectProduct(detail, $event)">
                          </ngx-select2>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-4 col-sx-4">
                        <div class="form-group">
                          <!-- <label class="label">Stt</label> -->
                          <input type="text" nbInput fullWidth formControlName="No" placeholder="Stt">
                        </div>
                      </div>
                      <div class="col-md-8 col-sm-8 col-is-8">
                        <div class="form-group" [attr.state]="formControlValidate(detail.get('Type'), 'warning')">
                          <label class="label">{{'Common.type' | translate | headtitlecase}}</label>
                          <input type="text" nbInput fullWidth formControlName="Type"
                            placeholder="{{'Common.type' | translate | headtitlecase}}">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-12 col-sm-12 col-is-12">
                    <div class="row">
                      <!-- <div class="col-md-2 col-sm-4 col-is-4">
                        <div class="form-group" [attr.state]="formControlValidate(detail.get('Quantity'), 'warning')">
                          <label class="label">{{'Voucher.quantity' | translate | headtitlecase}}</label>
                          <input type="text" nbInput fullWidth formControlName="Quantity"
                            placeholder="{{'Voucher.quantity' | translate | headtitlecase}}" class="align-right"
                            (keyup)="toMoney(formItem, detail)" currencyMask [options]="numberFormat">
                        </div>
                      </div> -->
                      <div class="col-md-4 col-sm-4 col-is-4">
                        <div class="form-group">
                          <label class="label">{{'Voucher.unit' | translate | headtitlecase}}</label>
                          <ngx-select2 formControlName="Unit" [data]="unitList" [select2Option]="select2OptionForUnit">
                          </ngx-select2>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-4 col-is-4">
                        <div class="form-group" [attr.state]="formControlValidate(detail.get('Price'), 'warning')">
                          <label class="label">{{'Voucher.price' | translate | headtitlecase}}</label>
                          <input type="text" nbInput fullWidth formControlName="Price"
                            placeholder="{{'Voucher.price' | translate | headtitlecase}}" class="align-right"
                            (keyup)="toMoney(formItem, detail)" currencyMask [options]="curencyFormat">
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-4 col-is-4">
                        <div class="form-group" [attr.state]="formControlValidate(detail.get('Tax'), 'warning')">
                          <label class="label align-right">{{'Voucher.tax' | translate | headtitlecase}}</label>
                          <nb-icon class="copy-btn"
                            (click)="copyFormControlValueToOthers(getDetails(formItem), ic, 'Tax')" pack="eva"
                            icon="copy-outline" style="position: absolute; z-index: 1; right: -14px; top: -14px;">
                          </nb-icon>
                          <ngx-select2 [data]="taxList" formControlName="Tax" (selectChange)="toMoney(formItem, detail)"
                            [select2Option]="select2OptionForTax">
                          </ngx-select2>
                        </div>
                      </div>
                      <!-- <div class="col-md-3 col-sm-8 col-is-8">
                        <div class="form-group" [attr.state]="formControlValidate(detail.get('ToMoney'), 'warning')">
                          <label class="label align-right">{{'Voucher.toMoney' | translate | headtitlecase}}</label>
                          <input type="text" nbInput fullWidth formControlName="ToMoney" placeholder="Thành tiền"
                            class="align-right" currencyMask [options]="curencyFormat">
                        </div>
                      </div> -->
                      <div class="col-md-12 col-sm-12">
                        <div class="form-group" [attr.state]="formControlValidate(detail.get('Description'), 'warning')">
                          <label class="label">{{'Common.description' | translate | headtitlecase}}</label>
                          <input type="text" nbInput fullWidth formControlName="Description"
                            placeholder="{{'Common.description' | translate | headtitlecase}}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-12 col-is-12">
                  <button nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero
                        [disabled]="isProcessing">
                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                        {{'Sales.addProduct' | translate | headtitlecase}}
                      </button>
                      <input type="file" (change)="onFileChange($event, formItem)">
                </div>
                <div class="col-lg-3 col-md-8 col-sm-12 col-is-12"></div>
                <div class="col-lg-7 col-md-12 col-sm-12 col-is-12">
                  <div class="row">
                    <div class="col-md-2 col-sm-4 col-is-4"></div>
                    <div class="col-md-2 col-sm-4 col-is-4"></div>
                    <div class="col-md-2 col-sm-4 col-is-4"></div>
                    <div class="col-md-3 col-sm-4 col-is-4" style="text-align: right; font-weight: bold;">
                      {{'Voucher.total' | translate | headtitlecase}}</div>
                    <div class="col-md-3 col-sm-8 col-is-8" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">
                      <label>{{formItem.get('_total').value | currency:'VND'}}</label></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12" style="text-align: right;">

              </div>
            </div>
            <!-- End Detail form -->


          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
              <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
            </button>
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero
              (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;"
              hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
