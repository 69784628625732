<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup" style="width: 90%; margin: 0 auto;">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Contact.Contact.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
                        [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Contact.contactTitle" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Contact.contactTitle' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="ShortName" label="Contact.shortName" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ShortName" placeholder="{{'Contact.shortName' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-4">
                                <ngx-form-group [formGroup]="formItem" name="Name" label="Contact.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Contact.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Code" label="Contact.code" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Contact.code' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Organizations" label="Common.organizations" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                                    <ngx-select2 [select2Option]="select2Option" formControlName="Organizations"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Groups" label="Common.groups" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                                    <ngx-select2 [select2Option]="select2GroupsOption" [data]="groupList" formControlName="Groups"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Email" label="Contact.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="Email" placeholder="{{'Contact.email' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Phone" label="Contact.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="Phone" placeholder="{{'Contact.phone' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Address" label="Contact.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="Address" placeholder="{{'Contact.address' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Note" label="Contact.note" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea nbInput fullWidth formControlName="Note" placeholder="{{'Contact.note' | translate | headtitlecase}}"></textarea>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">

                                <!-- Detail form -->
                                <div class="row fit-row form-detail-header">
                                    <div class="fit-fist-col label column">Stt</div>
                                    <div class="row fit-content-column">
                                        <div class="col-sm-3 label column">{{'Common.type' | translate | headtitlecase}}</div>
                                        <div class="col-sm-9 label column">{{'Common.detail' | translate | headtitlecase}}</div>
                                    </div>
                                    <div class="fit-last-col column">
                                        <button class="fit-control-button" nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero>
                                            <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                        </button>
                                    </div>
                                </div>

                                <div formArrayName="Details" class="form-details">
                                    <!-- <div formArrayName="Details" class="form-details"> -->
                                    <hr>
                                    <div class="form-detail-item" *ngFor="let detail of getDetails(formItem).controls; let ic=index" [formGroup]="detail">
                                        <input type="hidden" formControlName="Id">

                                        <div class="row fit-row">
                                            <div class="fit-fist-col label">
                                                <div class="form-group">
                                                    <!-- <label class="label">Stt</label> -->
                                                    <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ic + 1 }}">
                                                    <input type="hidden" formControlName="Id">
                                                </div>
                                            </div>
                                            <div class="row fit-content-column">
                                                <div class="col-sm-3">
                                                    <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Type" label="Contact.type" [array]="getDetails(detail)" [index]="ic" [allowCopy]="false" [required]="true">
                                                        <ngx-select2 formControlName="Type" [select2Option]="select2OptionForType" [data]="typeList"></ngx-select2>
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-9">
                                                    <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Detail" label="Contact.detail" [array]="getDetails(detail)" [index]="ic" [allowCopy]="false" [required]="true">
                                                        <input nbInput fullWidth formControlName="Detail" placeholder="{{'Common.detail' | translate | headtitlecase}}">
                                                    </ngx-form-group>
                                                </div>
                                            </div>
                                            <div class="fit-last-col">
                                                <div class="form-group" style="text-align: right;">
                                                    <!-- <label class="label">CM</label> -->
                                                    <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeDetailGroup(formItem, detail, ic)">
                                                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Detail form -->
                            </div>
                            <div class="col-sm-12">
                                <br>
                                <hr>
                                <label class="label">Liên kết với các nền tảng khác</label>

                                <!-- Detail form -->
                                <div class="row fit-row form-detail-header">
                                    <div class="fit-fist-col label column">Stt</div>
                                    <div class="row fit-content-column">
                                        <div class="col-sm-2 label column">{{'Common.plaform' | translate | headtitlecase}}</div>
                                        <div class="col-sm-4 label column">{{'Common.page' | translate | headtitlecase}}</div>
                                        <div class="col-sm-4 label column">{{'Common.useruid' | translate | headtitlecase}}</div>
                                        <div class="col-sm-2 label column">{{'Common.state' | translate | headtitlecase}}</div>
                                    </div>
                                    <div class="fit-last-col column">
                                        <button class="fit-control-button" nbButton status="success" (click)="addOutsideReferenceFormGroup(formItem)" size="medium" hero>
                                            <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                        </button>
                                    </div>
                                </div>

                                <div formArrayName="OutsideReferences" class="form-details">
                                    <!-- <div formArrayName="Details" class="form-details"> -->
                                    <hr>
                                    <div class="form-detail-item" *ngFor="let outsideReference of getOutsideReferences(formItem).controls; let io=index" [formGroup]="outsideReference">
                                        <input type="hidden" formControlName="Id">

                                        <div class="row fit-row">
                                            <div class="fit-fist-col label">
                                                <div class="form-group">
                                                    <!-- <label class="label">Stt</label> -->
                                                    <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ io + 1 }}">
                                                    <input type="hidden" formControlName="Id">
                                                </div>
                                            </div>
                                            <div class="row fit-content-column">
                                                <div class="col-sm-2">
                                                    <ngx-form-group [hideLabel]="true" [formGroup]="outsideReference" name="Platform" label="Contact.outsideReferencePlatform" [array]="getOutsideReferences(outsideReference)" [index]="io" [allowCopy]="false" [required]="true">
                                                        <ngx-select2 formControlName="Platform" [select2Option]="select2OptionForPlatForm" [data]="platformList"></ngx-select2>
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-4">
                                                    <ngx-form-group [hideLabel]="true" [formGroup]="outsideReference" name="Page" label="Contact.page" [array]="getOutsideReferences(outsideReference)" [index]="io" [allowCopy]="false" [required]="true">
                                                        <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage"></ngx-select2>
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-4">
                                                    <ngx-form-group [hideLabel]="true" [formGroup]="outsideReference" name="UserUid" label="Contact.useruid" [array]="getOutsideReferences(outsideReference)" [index]="io" [allowCopy]="false" [required]="true">
                                                        <input nbInput fullWidth formControlName="UserUid" placeholder="{{'Common.useruid' | translate | headtitlecase}}">
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-2">
                                                    <ngx-form-group [hideLabel]="true" [formGroup]="outsideReference" name="State" label="Contact.state" [array]="getOutsideReferences(outsideReference)" [index]="io" [allowCopy]="false" [required]="true">
                                                        <ngx-select2 formControlName="State" [select2Option]="select2BaseOption" [data]="[{id: 'FOLLOW', text: this.commonService.translateText('Common.follow')}, {id: 'UNFOLLOW', text: this.commonService.translateText('Common.unfollow')}, {id: 'ZNSREQUEST', text: this.commonService.translateText('Common.znsRequest')}]"></ngx-select2>
                                                    </ngx-form-group>
                                                </div>
                                            </div>
                                            <div class="fit-last-col">
                                                <div class="form-group" style="text-align: right;">
                                                    <!-- <label class="label">CM</label> -->
                                                    <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeOutsideReferenceGroup(formItem, outsideReference, i)">
                                                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Detail form -->
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>

        </div>
    </div>
</form>