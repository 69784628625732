<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Purchase.Voucher.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <label class="label label-with-border-below">{{'Common.contactInfo' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">
                                <nb-tabset>
                                    <nb-tab tabTitle="{{'Common.mainInfo' | translate | headtitlecase}} (*)">
                                        <!-- <label class="label label-with-border-below">{{'Common.mainInfo' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <input type="hidden" formControlName="Code">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="Object" label="Voucher.organizationOrPersornal" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="Object" [select2Option]="select2OptionForContact" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectPhone" placeholder="{{formItem.get('ObjectPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectAddress" placeholder="{{formItem.get('ObjectAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectEmail" placeholder="{{formItem.get('ObjectEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                    <nb-tab tabTitle="{{'Common.contact' | translate | headtitlecase}}">
                                        <!-- <label class="label label-with-border-below">{{'Common.contact' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="Contact" label="Common.contact" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [customIcons]="contactControlIcons">
                                                    <ngx-select2 formControlName="Contact" [select2Option]="select2OptionForContact" (selectChange)="onContactChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ContactName" label="Contact.name" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ContactName" placeholder="{{'Contact.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ContactPhone" label="Contact.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ContactPhone" placeholder="{{formItem.get('ContactPhone').placeholder || ('Contact.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ContactIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ContactIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ContactAddress" label="Contact.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ContactAddress" placeholder="{{formItem.get('ContactAddress').placeholder || ('Contact.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ContactEmail" label="Contact.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ContactEmail" placeholder="{{formItem.get('ContactEmail').placeholder || ('Contact.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                </nb-tabset>
                            </div>
                        </div>

                        <label class="label label-with-border-below">{{'Common.receivedAndPayment' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="ObjectBankName" label="Bank.name" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectBankName" placeholder="{{'Bank.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="ObjectBankCode" label="Bank.code" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectBankCode" placeholder="{{'Bank.code' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <ngx-form-group [formGroup]="formItem" name="DateOfPurchase" label="{{validateVoucherDate(formItem.get('DateOfPurchase'), 'Purchase.dateOfPurchase')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="DateOfPurchase" [owlDateTimeTrigger]="DateOfPurchase" nbInput fullWidth formControlName="DateOfPurchase" placeholder="{{'Purchase.dateOfPurchase' | translate | headtitlecase}}">
                                            <owl-date-time #DateOfPurchase [startAt]="formItem.get('DateOfPurchase')?.value || this.commonService.lastVoucherDate"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-md-6">
                                        <ngx-form-group [formGroup]="formItem" name="IncludeInvoice" label="Có hóa đơn" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                            <nb-checkbox class="form-group-icon-control" formControlName="IncludeInvoice" nbTooltip="Mua hàng có hóa đơn VAT"></nb-checkbox>
                                            <input class="form-group-input-control" type="text" nbInput fullWidth formControlName="InvoiceNumber" placeholder="{{'Số hóa đơn' | translate | headtitlecase}}" nbTooltip="Nhập số hóa đơn VAT">
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="DeliveryAddress" label="Sales.deliveryAddress" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="DeliveryAddress" placeholder="{{'Sales.deliveryAddress' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <button class="fit-control-button" [outline]="true" nbButton status="primary" fullWidth (click)="openRelativeVoucherChoosedDialog(formItem)" size="medium" hero>
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm chứng từ liên quan
                                    </button>
                                </ngx-form-group>
                            </div>

                            <div class="col-sm-12" *ngIf="formItem.get('RelativeVouchers').value?.length > 0">
                                <div class="form-group">
                                    <label class="label"><span class="valid">{{'Common.relativeVoucher' | translate | headtitlecase}}: </span></label>
                                    <div>
                                        <span class="tag" *ngFor="let relationVoucher of formItem.get('RelativeVouchers').value" nbTooltip="{{relationVoucher.typeMap?.text}}: {{relationVoucher | objectstext}}">
                                            <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.typeMap?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                                            <nb-icon pack="eva" icon="close-outline" class="close-btn" (click)="removeRelativeVoucher(formItem, relationVoucher)"></nb-icon>
                                        </span>
                                    </div>
                                    <div class="break"></div>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>

                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Note" label="Common.note" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="Note" placeholder="{{'Common.note' | translate | headtitlecase}}" rows="4"></textarea>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="SubNote" label="Common.subNote" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="SubNote" placeholder="{{'Common.subNote' | translate | headtitlecase}}" rows="3"></textarea>
                                </ngx-form-group>
                            </div>
                        </div>

                        <!-- Detail form -->
                        <label class="label label-with-border-below">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">
                                <!-- <div class="row">
                                    <div class="col-sm-2 label">{{'Common.noNumber' | translate | headtitlecase}}/{{'Common.photo' | translate | headtitlecase}}</div>
                                    <div class="col-lg-10">
                                        <div class="row">
                                            <div class="col-sm-4 label">
                                                <div class="row">
                                                    <div class="col-sm-4 label">{{'Common.type' | translate | headtitlecase}}</div>
                                                    <div class="col-sm-8 label">{{'Common.goodsOrService' | translate | headtitlecase}}</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-8 label">
                                                <div class="row">
                                                    <div class="col-sm-3 label align-right">{{'Voucher.quantity' | translate | headtitlecase}}</div>
                                                    <div class="col-sm-2 label">{{'Voucher.unit' | translate | headtitlecase}}</div>
                                                    <div class="col-sm-4 label align-right">{{'Giá EU' | translate | headtitlecase}}</div>
                                                    <div class="col-sm-3 label align-right">{{'Voucher.toMoney' | translate | headtitlecase}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <div formArrayName="Details" class="form-details" [sortablejs]="getDetails(formItem)" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                                    <div class="form-detail-item" *ngFor="let detail of getDetails(formItem).controls; let ic=index" [formGroup]="detail">
                                        <hr>
                                        <div class="row">
                                            <div class="col-lg-2 col-md-2 col-sm-12 col-is-12">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-is-3">
                                                        <ngx-form-group [hideLabel]="false" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'" [formGroup]="detail" name="No" label="STT" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                                            <input nbInput class="sorting-handle" title="Kéo thả để di chuyển" fullWidth formControlName="No" value="{{ic+1}}" readonly style="text-align: center; cursor: grab; width: 100%; height: 2.5rem; padding: 0;">
                                                            <!-- <input type="hidden" formControlName="Id"> -->
                                                        </ngx-form-group>
                                                        <div class="form-group">
                                                            <label class="label" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">Gở</label>
                                                            <button nbButton status="{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? 'danger' : 'primary'}}" hero size="medium" (click)="removeDetailGroup(formItem, detail, ic)" style="width: 100%; margin-bottom: 3px; padding: 0">
                                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-9 col-md-9 col-sm-9 col-is-9">
                                                        <ngx-form-group [hideLabel]="false" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'" [formGroup]="detail" name="Image" label="Common.image" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                                            <ngx-files-input formControlName="Image" [config]="{style: {height: '6.9rem', padding: '0'}, thumbnailStype: {height: '5rem', width: '100%'}}"></ngx-files-input>
                                                        </ngx-form-group>
                                                        <div class="form-group" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">
                                                            <label class="label">Di chuyển</label>
                                                            <input type="text" nbInput fullWidth placeholder="Kéo để di chuyển" disabled class="sorting-handle" style="cursor: pointer;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 col-md-10 col-sm-12 col-is-12">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-sm-12 col-is-12">
                                                        <div class="row">
                                                            <div class="col-lg-{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? '4' : '12'}} col-sm-{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? '3' : '12'}} col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Type" label="Common.type" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                    <ngx-select2 [data]="select2DataForType" formControlName="Type" (selectChange)="toMoney(formItem, detail, 'Type', ic)" [select2Option]="select2OptionForType"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-8 col-md-8 col-sm-9   col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Product" label="Common.productOrService" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="trie" [touchedValidate]="false" [customIcons]="getCustomIcons(i + ic)" [option]="{parentForm: formItem}">
                                                                    <ngx-select2 formControlName="Product" [select2Option]="select2OptionForProduct" (selectChange)="onSelectProduct(detail, $event, formItem, detail)"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-sm-12 col-is-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 col-md-3 col-sm-3 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Quantity" label="Voucher.quantity" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="Quantity" placeholder="{{'Voucher.quantity' | translate | headtitlecase}}" class="align-right" (keyup)="toMoney(formItem, detail, 'Quantity', ic)" [inputMask]="towDigitsInputMask">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Unit" label="Voucher.unit" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false" [customIcons]="unitCustomIcons">
                                                                    <ngx-select2 formControlName="Unit" [data]="detail.get('Unit')['UnitList'] || unitList" [select2Option]="select2OptionForUnit"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Price" label="Giá EU" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="Price" placeholder="{{'Voucher.price' | translate | headtitlecase}}" class="align-right" (keyup)="toMoney(formItem, detail, 'Price', ic)" currencyMask [options]="priceCurencyFormat" (focus)="currencyMaskFocus($event, priceCurencyFormat)" (keydown)="currencyMastKeydown($event, priceCurencyFormat)" (paste)="onPasteNumber($event, priceCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3 col-sm-3 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="ToMoney" label="Voucher.toMoney" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="ToMoney" placeholder="Thành tiền" class="align-right" currencyMask [options]="toMoneyCurencyFormat" (keyup)="toMoney(formItem, detail, 'ToMoney', ic)" (focus)="currencyMaskFocus($event, toMoneyCurencyFormat)" (keydown)="currencyMastKeydown($event, toMoneyCurencyFormat)" (paste)="onPasteNumber($event, toMoneyCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="1"></textarea>
                                                                </ngx-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Business" label="Common.accountingBusiness" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                            <ngx-select2 [data]="accountingBusinessList" formControlName="Business" (selectChange)="toMoney(formItem, detail, 'Business', ic)" [select2Option]="select2OptionForAccountingBusiness"></ngx-select2>
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                            <textarea class="scrollable-container" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="1"></textarea>
                                                        </ngx-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-4 col-sm-12 col-is-12">
                                            <button nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero [disabled]="isProcessing">
                                                <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Common.addProduct' | translate | headtitlecase}}
                                            </button>
                                        </div>
                                        <div class="col-lg-3 col-md-8 col-sm-12 col-is-12"></div>
                                        <div class="col-lg-7 col-md-12 col-sm-12 col-is-12">
                                            <div class="row">
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-3 col-sm-4 col-is-4" style="text-align: right; font-weight: bold;">{{'Voucher.total' | translate | headtitlecase}}</div>
                                                <div class="col-md-3 col-sm-8 col-is-8" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">{{formItem.get('_total').value | currency:'VND'}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-3 col-sm-4 col-is-4" style="text-align: right; font-weight: bold;">{{'Nợ cũ' | translate | headtitlecase}}</div>
                                                <div class="col-md-3 col-sm-8 col-is-8" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">{{previousDebt | currency:'VND'}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-3 col-sm-4 col-is-4" style="text-align: right; font-weight: bold;">{{'Tổng nợ' | translate | headtitlecase}}</div>
                                                <div class="col-md-3 col-sm-8 col-is-8" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">{{(previousDebt + formItem.get('_total').value) | currency:'VND'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End Detail form -->

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview([formItem.value])" [disabled]="!form.valid || isProcessing">
                            <nb-icon on b-icon pack="eva" icon="printer"></nb-icon>{{'Common.preview' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>